import httpUrl from './Config';
let uploadFile = ''
let uploadExcel = ''
let systemTitle = httpUrl.title
if (process.env.NODE_ENV === 'development') {
	uploadFile = httpUrl.devUrl + '/';
	uploadExcel = httpUrl.devUrl + '/';
} else {
	uploadFile = httpUrl.proUrl + '/';
	uploadExcel = httpUrl.proUrl + '/';
}

export default {
	// 公用部分
	sendSms: '/api/send_sms', // 获取验证码
	getArea: '/api/category/city_area', // 获取地区
	upLoad: uploadFile + 'api/wechat/upload', // 上传接口 文件、图片
	spaceTypes: '/api/category/space_type', // 空间类型
	houseTypes: '/api/category/house', // 房源类型
	getDirections: '/api/category/orients', // 获取朝向
	getInfrastructures: '/api/category/devices', // 获取基础设施
	industry: '/api/category/industry', //行业领域
	getAttribute: '/api/category/attribute', //属性标签列表
	systemTitle: systemTitle, // 系统名称
	levels: '/api/category/levels', //孵化器级别列表
	sort:'/api/category/attract/category', //招商条件分类
	demands:'/api/demands', //招商条件需求
	undefineds: '/api/category/attract/category', //自定义招商分类
	lists: '/api/demands', //获取需求列表
	details: '/api/demands', //获取招商需求详情
	citysTwoChildren: '/api/category/province_city', // 没有地区的地区接口
	matelists: 'api/enterprises', //匹配企业列表
	weight: '/api/match/resource/weight', //权重比详情
	follow: 'api/enterprises/follow', //匹配企业跟进
	status: '/status', //招商需求启用禁用
	// 超管后台
	superUrl: {
		superlogin: '/api/admin/login_for_password', //密码登录
		sendSms: '/api/send_sms', // 获取验证码
		resetPassword: 'api/admin/reset_password', //重置密码
		organs: 'api/admin/organs', //机构列表
		edit: '/edit', //所有编辑页面的后缀
		codeLogin: 'api/admin/login_for_code', //验证码登录
		superUpload: uploadFile + 'api/admin/upload', //超管上传
		checkSubmit: '/api/admin/organs/check_submit', //审核机构状态
		areaCode: '/api/category/area_id', // 地图获取 当前位置编号
		organsMap: '/api/admin/organs/maps', // 机构列表筛选 地图
		mapKey: '/api/category/options', // 获取地图key
		downloadExcel: uploadExcel + 'api/admin/download/excel/view', //下载excel模板
		uploadExcel: uploadExcel + 'api/admin/upload/excel/view', //上传excel模板
		excelOrgans: uploadExcel + 'api/admin/upload/excel/organs', //批量上传机构
		adminTasks:'api/admin/tasks/',//超管任务管理人任务列表
		demandList: '/api/admin/market/demands', //需求列表
		demandDel: '/api/admin/market/demands/', //删除需求
		demandShow: '/api/admin/market/demands/toggle/show', //需求展示隐藏
		demandListSuper: '/api/admin/market/demands/types', //需求类型列表
		demandTypeInfoSuper: '/api/admin/demand_type/info/', //编辑&&删除需求类型
		demandTypeAddSuper: '/api/admin/market/demands/add/types', //自定义添加需求类型分类
	},

	// 机构后台
	adminUrl: {
		passwordLogin: '/api/organs/login_for_password', // 密码登录
		codeLogin: '/api/organs/login_for_code', // 验证码登录
		updatePassword: '/api/wechat/reset_password', // 修改密码
		delSpace: '/api/organs/spaces/', // 删除空间
		spaceList: '/api/organs/organs/', // 空间列表
		setSpace: '/api/organs/spaces', // 空间 - 添加或修改 、详情
		space_level: '/api/category/space_level', //空间级别
		organizationList: '/api/organs/my', // 机构列表
		organAddEdit: 'api/organs/company_in_organ_add_or_edit', //企业入驻—新增&修改
		delCompany: 'api/organs/company_in_organ_delete/company/', //企业入驻—删除
		organDetail: 'api/organs/company_in_organ_detail/organ/', //企业入驻—列表
		companyDetail: '/api/organs/company/Company/', //企业入驻—详情
		teamOrgan: 'api/organs/team/organ/', //团队服务—列表
		delTeam: '/api/organs/team/team/', //团队服务—删除
		addTeam: '/api/organs/team', //团队服务—新增
		stagesList: 'api/category/stages', //融资轮次
		salesList: 'api/category/sales', //销售收入
		honorsList: 'api/category/honors', //企业荣誉
		
		cooperationList: 'api/cooperation/organs/cooperation/', //空间-合作资源
		searchOrgans: 'api/cooperation/organs/search', // 空间-合作资源添加-检测机构是否存在
		cooperationRow: 'api/cooperation/cooperation/', // 空间-合作资源列表-单条数据操作
		cooperationAdd: '/api/cooperation/organs/cooperation/', // 空间-合作资源添加-添加合作资源
		mediaOrActivityList: '/api/organs/media/activity', // 空间-活动案例或者媒体资源


		policy: '/api/category/policy/scales', //找政策 - 分类 - 规模
		industry: '/api/category/industry/scales', //找政策 - 分类 - 行业规模
		engages: '/api/category/scale/engages', //找政策 - 分类 - 规模从业人数
		revenues: '/api/category/scale/revenues', //找政策 - 分类 - 营业收入
		funds: '/api/category/scale/funds', //找政策 - 分类 - 规模资产
		starts: '/api/category/industry/starts', //找政策 - 分类 - 创投行业列表
		economics: '/api/category/industry/economics', //找政策 - 分类 - 国民经济行业列表
		systems: "/api/category/policy/systems", //找政策 - 分类 - 政策体系
		types: "/api/category/policy/types", //找政策 - 分类 - 政策类型
		type: "/api/category/policy/type", //找政策-政策类型-新增
		system: "/api/category/policy/system", //找政策-政策体系-新增
		addMaterial: '/api/organs/article', //政策-添加
		articleList: '/api/organs/article/organ/', //政策-列表
		delPolicyArticle: '/api/organs/article/PolicyArticle/', //政策-删除
		article_types: '/api/micro_service/article_types', //政策 - 专栏 - 列表,政策 - 专栏 - 新增
		declare_number: '/api/organs/article/declare_number/PolicyArticle/', //政策-申报人数

		PolicyArticle: '/api/organs/article/type/PolicyArticle/', //政策-专栏
		articleActiveUpdata: '/api/micro_service/article_types/', //政策 - 专栏 - 启用或禁用,政策 - 专栏 - 删除
		CategoryArticleType: 'api/organs/article/CategoryArticleType/', //政策-专栏下政策列表
		policySort: 'api/organs/article/sort/PolicyArticle/', //政策-排序
		search_organs: 'api/organs/policy_article/search_organs', //政策-机构列表
		areaTree: 'api/category/area_tree', //获取地区 - 分级显示
		PolicyArticleYes: "api/organs/policy_article/has/article_type/PolicyArticle/", //已选专栏
		/* 全局管理 */
		managersList: 'api/manager/organs/', //机构成员列表
		permissions: 'api/manager/permissions', //所有权限列表
		/* 企业追踪 start */
		arrangements: '/api/category/arrangements', //层次
		stages: 'api/organs/oa/stages', //阶段设置-阶段列表
		delStags: 'api/organs/stages', //阶段设置-阶段删除
		treeSort: 'api/organs/stages/sort', //阶段设置-自定义排序
		projectUsers: 'api/category/project/users/organ/', //项目-跟进人列表
		projectEdit: '/api/organs/project', //招商OA-项目编辑,招商OA-项目编辑(详情用于回显)
		projects: 'api/organs/projects', //项目列表
		journal: 'api/organs/project/journal', //招商OA-日志列表
		editJournal: 'api/organs/journal/', //招商OA-日志编辑
		taskRelease: 'api/organs/project/task_release', //任务发布
		labels: '/api/category/labels', //项目标签
		editProject: '/api/organs/project', //编辑项目
		qianzai: 'api/organs/project/users/Project/', //项目列表下的潜在投资人列表
		editTask: 'api/organs/project/task_release/ProjectHasOaStage/', //编辑任务发布
		jurisdiction: 'api/organs/journal/jurisdiction', //招商OA-项目日志判断是否有操作权限
		/* 企业追踪 end */
		// 政策素材列表下的项目列表
		policy_prolist: 'api/organs/article/projects/PolicyArticle/', // 政策下面的项目列表
		local_policy_prolist: 'api/organs/article/organ/', // 政策下面的项目列表
		policy_pro_investlist: 'api/organs/project/users/Project/', // 政策下面的潜在投资方
		policy_pro_genjin: '/api/organs/project/follow', // 政策下面的跟进
		policy_space_list: 'api/organs/article/', // 政策匹配下的空间列表
		policy_spacelist: 'api/organs/project/', // 落地园区
		policy_pro_policylist: '/api/organs/projects/match/policies', // 项目匹配的政策列表
		add_column_local_list: 'api/organs/article/organ/article_type/local_article/', // 给专栏添加素材的本地政策列表
		add_column_all_list: 'api/organs/article/organ/article_type/all_article/', // 给专栏添加素材的全网政策列表
		submit_column_local: 'api/organs/article/organ/article_type/add_article/',
		delete_column_data: 'api/organs/article/organ/article_type/delect_article/', // 删除政策
		//精准通3期
		pushCompanies: '/api/push/companies/', //政策速递企业列表
		pushToggle: '/api/push/companies/toggle', //移除/加入推送
		pushSet: '/api/push/settings', //推送配置
		PolicyDetail: 'api/wechat/policy_article/PolicyArticle/', //政策-详情(小程序)
		PolicyQr: 'api/wechat/policy_article/qrcode/PolicyArticle/', //政策-二维码
		//精准通--访客画像
		visitorsList: '/api/organs/organs/users/lists', //访客列表
		categoryDemands: '/api/category/demands', //需求标签列表(添加政策时用)
		identitiesCount: '/api/organs/identities/count', //用户身份属性统计
		demandsCount: 'api/organs/demands/count', //政策需求统计
		singleActions: '/api/organs/organs/users/', //单个用户的用户画像
		orderDemands: 'api/category/demands', //自定义添加需求标签
		//科技资源&科研仪器
		resource: '/api/resource/techs/', //科技资源列表
		instruments: '/api/resource/instruments/', //科研仪器列表
		instCategory: '/api/category/instruments', //科研仪器分类
		techCategory: '/api/category/tech/resource', //科技资源分类
		
		// 招商分析
		getScreenRange: 'api/admin/screen/range', //获取大屏列表
		screenAlgorithm: 'api/admin/screen/range/algorithm', //展示数据计算	
		industryXs: '/api/xiaoshan/industryXs', // 萧山八大产业
		industryHot: '/api/xiaoshan/industryHot', // 产业热度
		industryPattern: '/api/xiaoshan/industryPattern', // 产业格局
		industryCityRank: '/api/xiaoshan/industryCityRank/', // 产业分布
		industryTrend: '/api/xiaoshan/industryTrend', // 产业趋势
		industryDetail: '/api/xiaoshan/industryDetail/', // 细分产业
		getXiaoShanProjectList: '/api/xiaoshan/projectList', // 招商项目		
		
		// 园区选址
		parkList: 'api/site/site', // 园区选址(空间)
		searchSpace:'api/site/search/space', //根据空间名搜索空间
		searchArticle: 'api/site/search/policy/article', //根据政策名搜索政策
		addSpace: 'api/site/space', //搜说不到空间后新增空间
		spaceTax: 'api/site/tax/', //园区选址详情里税务新增或修改
		siteAddress: 'api/site/address',  //地址管理：地址列表
		addressChange: 'api/site/address/change_status/', //地址管理：地址修改使用状态
		siteOrders: 'api/site/site/orders/', //订单列表
		orderAddress: 'api/site/order/address/', //订单分配地址
		orderPayStatus:'api/site/order/pay_status/', //订单设置线下支付状态和时间
		parkTemplate: 'api/site/download/site/template',//下载园区选址上传的模板
		uploadParkAddress: uploadExcel + '/api/site/upload/site', //批量上传园区选址
	},
	rcmyUrl: 'https://web.rongchuangmianyang.com/workbench', // http://my.wtscn.weitianshi.com.cn/workbench
	rcmyUrlEmpty: 'https://www.weitianshi.cn/workbench'
}
