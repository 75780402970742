import Vue from 'vue'
import App from './App.vue'
import router from './router/Router.js'
import 'default-passive-events'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store/Index'
import axios from './config/SuperAxios' // axios请求配置 超管后台
import backAxios from './config/BackgroundAxios' // axios请求配置 机构后台
import URL_ from './config/url' // URL请求地址汇总
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/iconfont/iconfont.css' // 引入阿里矢量库图标
import echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.use(VueQuillEditor)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$http = backAxios 
Vue.prototype.$superHttp = axios
Vue.prototype.URL = URL_
// Vue.prototype.duration = 1000
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
