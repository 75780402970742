let httpUrl = {}
// let proUrl = 'https://space.api.rongchuangmianyang.com' // 线上 绵阳
// let proUrl = 'https://spapi.weitianshi.cn' // 微天使https://api.shuzhidongli.com
let proUrl = 'https://api.shuzhidongli.com' // 一个新的招商通----数智动力
 let devUrl = 'https://my.spapi.weitianshi.com.cn' // 测试 绵阳
// httpUrl.title = '融创绵阳创新创业综合服务平台'
// httpUrl.title = '微天使创投服务生态链接者'
httpUrl.title = '数智动力'
httpUrl.proUrl = proUrl
httpUrl.devUrl = proUrl
export default httpUrl
