import axios from 'axios'
import httpUrl from './Config';
import { error } from '../utils/notification';

let devUrl = httpUrl.devUrl  // 测试 接口地址
let proUrl = httpUrl.proUrl // 线上接口地址

// 这个baseUrl要根据实际情况进行改变
// axios.defaults.baseURL = "/"
// axios.defaults.headers.common["Content-Type"] =
//  "application/json; charset=UTF-8"
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.superToken;
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = devUrl
} else {
    axios.defaults.baseURL = proUrl
}
// 请求拦截器 
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = 'Bearer' +  sessionStorage.superToken;
        return config
    },
    err => {
		
        return Promise.reject(err)
    }
)

// 响应拦截器即异常处理
axios.interceptors.response.use(
    response => {
        return response
    },
    err => {
        if (err.response.data.message) {
            error(err.response.data.message)
        }
        return Promise.reject(err)
    }
)

export default axios;
