<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	data() {
		return {
			name: 'app'
		};
	}
};
</script>

<style>
@import './assets/css/base.css';

html,
body {
	width: 100%;
	height: 100%;
}

#app {
	background-color: rgb(242, 245, 248);
	/*这里最小宽度不要加，如果哪个页面需要只在某个页面里加，暂时zhu*/
	/*min-width: 1400px;*/
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

}

.el-table td,
.el-table th {
	padding: 8px 0 !important;
}
.el-upload-list__item {
	width: 360px !important;
}
.el-input-group__append {
	padding: 0 8px !important;
}
.el-dialog__close {
	font-size: 24px;
}
.kaifazhong {
	color: #e24e4e;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 120px;
}
.policyCompilationTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-size: 18px;
	height: 40px;
}
/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
	-webkit-appearance: none !important;
	margin: 0;
}
/* 火狐 */
input[type="number"]{-moz-appearance:textfield;}
body .el-table th.gutter {
	display: table-cell !important;
}

body .el-table colgroup.gutter {
	display: table-cell !important;
}
</style>
