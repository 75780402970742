import axios from 'axios'
import httpUrl from './Config';
import {error} from "../utils/notification"
// import { error } from '../utils/notification';

let backAxios = axios.create();
let devUrl =httpUrl.devUrl  // 测试 接口地址
let proUrl = httpUrl.proUrl // 线上接口地址
// 这个baseUrl要根据实际情况进行改变
// axios.defaults.baseURL = "/"
// axios.defaults.headers.common["Content-Type"] =
//  "application/json; charset=UTF-8"
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"

backAxios.defaults.timeout = 60000;
backAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
backAxios.defaults.headers.common['Authorization'] = 'Bearer ' +  sessionStorage.adminToken;
if (process.env.NODE_ENV === 'development') {
    backAxios.defaults.baseURL = devUrl
} else {
    backAxios.defaults.baseURL = proUrl
}
// 请求拦截器 添加token
backAxios.interceptors.request.use(
    config => {
       config.headers.Authorization = 'Bearer' +  sessionStorage.adminToken;
       return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 响应拦截器即异常处理
backAxios.interceptors.response.use(
    response => {
        return response
    },
    err => {
      if (err.response.data.message) {
        error(err.response.data.message)
      }
         return Promise.reject(err)
    }
)

export default backAxios;
