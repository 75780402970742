import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    organInfo: {}
  },
  mutations: {
    setOrganInfo(state, data) {//存机构信息
      state.organInfo = data
    }
  },
  getters: {
    getOrganInfo(state) {//取机构信息
      return state.organInfo
    },
  }
})
export default store