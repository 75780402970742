import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push // 点击element-ui 子菜单 两次报错
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
	routes: [{
			path: '/Policy/detail/:id', // 邮件推送详情
			name: 'PolicyDetail',
			component: () => import('../pages/Admin/emailDetail/PolicyDetail.vue'),
			props: true
		},
		{
			path: '/empty', // 邮件推送详情
			name: 'empty',
			component: () => import('../pages/empty.vue'),
			props: true
		}, {
			path: '/superindex', // pc 超管后台管理对应路由配置
			name: 'superindex',
			component: () => import('../pages/SuperAdmin/SuperAdminIndex.vue'),
			redirect: '/organization',
			children: [
				// 这里写 超管后台管理对应路由配置
				{
					path: '/organization', //PC 超管后台发布机构
					name: 'Organization',
					component: () => import('../pages/SuperAdmin/organization/Organization.vue')
				},
				{
					path: '/organization/add', //PC 超管后台发布机构
					name: 'OrganizationAdd',
					component: () => import('../pages/SuperAdmin/organization/AddOrganization.vue')
				},
				{
					path: '/organization/edit/:id', //PC 超管后台编辑机构
					name: 'OrganizationEdit',
					component: () => import('../pages/SuperAdmin/organization/AddOrganization.vue'),
					props: true
				},
				{
					path: '/mapIndex', // PC 地图
					name: 'mapIndex',
					component: () => import('../pages/SuperAdmin/MapIndex.vue')
				},
				{
					path: '/taskManage', // PC 地图
					name: 'taskManage',
					component: () => import('../pages/SuperAdmin/taskManage/TaskManage.vue')
				},
				{
					path: '/demandMarketAdmin', // 需求市场-类别
					name: 'demandMarketAdmin',
					component: () => import('../pages/SuperAdmin/demandMarket/demandMarketAdmin.vue')
				},
				{
					path: '/demandMarketList', // 需求市场-管理
					name: 'demandMarketList',
					component: () => import('../pages/SuperAdmin/demandMarket/demandMarketList.vue')
				},				
			]
		},
		{
			path: '/superLogin', //PC 超管后台登录
			name: 'superLogin',
			component: () => import('../pages/SuperAdmin/login/SuperLogin.vue'),
		},
		{
			path: '/forgetPassword', //PC 超管后台忘记密码
			name: 'forgetPassword',
			component: () => import('../pages/SuperAdmin/login/ForgetPassword.vue'),
		},
		{ // 机构后台登录
			path: '/AdminLogin',
			name: 'AdminLogin',
			component: () => import('../pages/Admin/Login/AdminLogin.vue')
		},
		{
			path: '/loginReady',
			name: 'loginReady',
			// component: _import('login/loginReady')
			component: () => import('../pages/Admin/Login/loginReady.vue')
		},
		{
			path: '/', // 根路由
			redirect: '/adminIndex'
		},
		{
			path: '/xiaoShanInvestment',
			name: 'xiaoShanInvestment',
			component: () => import('../pages/Admin/EnterpriseAgglomeration/xiaoShanInvestment.vue')
		},
		{
			path: '/xiaoShanInvestmentM',
			name: 'xiaoShanInvestmentM',
			component: () => import('../pages/Admin/EnterpriseAgglomeration/xiaoShanInvestmentM.vue')
		},		
		{
			path: '/adminIndex', // pc 机构后台后台管理对应路由配置
			name: 'AdminIndex',
			component: () => import('../pages/Admin/index.vue'),
			redirect: 'memberList',
			children: [
				/* 全局管理  start*/
				{ //团队管理
					path: '/teamManger',
					name: 'teamManger',
					component: () => import('../pages/Admin/globalManger/teamManger.vue')
				},
				{ //成员列表
					path: '/memberList',
					name: 'memberList',
					component: () => import('../pages/Admin/globalManger/memberList.vue')
				},
				{ //添加成员
					path: '/addMember',
					name: 'addMember',
					component: () => import('../pages/Admin/globalManger/addMember.vue')
				},
				{ //成员详情
					path: '/memberDetail',
					name: 'memberDetail',
					component: () => import('../pages/Admin/globalManger/memberDetail.vue')
				},
				{ //权限列表
					path: '/permissionsList',
					name: 'permissionsList',
					component: () => import('../pages/Admin/globalManger/permissionsList.vue')
				},
				/* 全局管理  end*/
				/* 空间管理  start*/
				{ //登录列表
					path: '/loginList',
					name: 'loginList',
					component: () => import('../pages/Admin/spaceManger/loginList.vue')
				},
				{ //基本信息
					path: '/basicInfo',
					name: 'basicInfo',
					component: () => import('../pages/Admin/spaceManger/basicInfo.vue'),
					redirect: '/AddSpaceOrganization',
					children: [						
						{ //机构介绍
							path: '/AddSpaceOrganization',
							name: 'AddSpaceOrganization',
							component: () => import('../pages/Admin/spaceManger/AddSpaceOrganization.vue')
						},						
						{ //服务团队
							path: '/serverTeam',
							name: 'serverTeam',
							component: () => import('../pages/Admin/spaceManger/serverTeam.vue')
						},
						{ //合作资源
							path: '/cooperativeResources',
							name: 'cooperativeResources',
							component: () => import('../pages/Admin/spaceManger/cooperativeResources.vue')
						},					
						{ //活动案例
							path: '/activityCases',
							name: 'activityCases',
							component: () => import('../pages/Admin/spaceManger/activityCases.vue')
						},
						{ //媒体发布
							path: '/media',
							name: 'media',
							component: () => import('../pages/Admin/spaceManger/media.vue')
						},															
					]
				},
				{ //空间管理-合作资源-添加
					path: '/cooperativeAdd',
					name: 'cooperativeAdd',
					component: () => import('../pages/Admin/spaceManger/cooperativeAdd.vue')
				},
				{ //空间管理-会议室订单
					path: '/meetingOrder',
					name: 'meetingOrder',
					component: () => import('../pages/Admin/spaceManger/meetingOrder.vue')
				},				
				{ //空间管理-活动案例-添加
					path: '/activityAdd',
					name: 'activityAdd',
					component: () => import('../pages/Admin/spaceManger/activityAdd.vue')
				},
				{ //空间管理-媒体案例-添加
					path: '/mediaAdd',
					name: 'mediaAdd',
					component: () => import('../pages/Admin/spaceManger/mediaAdd.vue')
				},								
				{ //空间发布
					path: '/spacePublishing',
					name: 'spacePublishing',
					component: () => import('../pages/Admin/spaceManger/spacePublishing.vue')
				},
				{ //服务团队
					path: '/serverTeam',
					name: 'serverTeam',
					component: () => import('../pages/Admin/spaceManger/serverTeam.vue')
				},
				{ //入驻企业
					path: '/settledEnterprise',
					name: 'settledEnterprise',
					component: () => import('../pages/Admin/spaceManger/settledEnterprise.vue')
				},
				{ //园区选址
					path: '/parkLocation',
					name: 'parkLocation',
					component: () => import('../pages/Admin/spaceManger/parkLocation.vue')
				},
				{ //园区选址-编辑2
					path: '/parkEdit',
					name: 'parkEdit',
					component: () => import('../pages/Admin/spaceManger/parkEdit.vue')
				},						
				{ //园区选址-编辑1
					path: '/parkRelease',
					name: 'parkRelease',
					component: () => import('../pages/Admin/spaceManger/parkRelease.vue')
				},
				{ //园区选址-剩余地址
					path: '/parkSurplus',
					name: 'parkSurplus',
					component: () => import('../pages/Admin/spaceManger/parkSurplus.vue')
				},
				{ //园区选址-订单
					path: '/parkOrder',
					name: 'parkOrder',
					component: () => import('../pages/Admin/spaceManger/parkOrder.vue')
				},													
				{ //合作资源
					path: '/cooperResoure',
					name: 'cooperResoure',
					component: () => import('../pages/Admin/spaceManger/cooperResoure.vue')
				},
				/* 空间管理  end*/
				/* 企业集聚  start*/
				{ //政策汇编
					path: '/policyCompilation',
					name: 'policyCompilation',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/policyCompilation.vue')
				},
				{ //企业库
					path: '/enterpriseLibrary',
					name: 'enterpriseLibrary',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/enterpriseLibrary.vue')
				},
				{ //企业追踪
					path: '/enterpriseTracking',
					name: 'enterpriseTracking',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/enterpriseTracking.vue')
				},
				{ //企业追踪
					path: '/viewLog',
					name: 'viewLog',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/viewLog.vue')
				},
				/* 企业集聚  end*/
				/* 数据管理  start*/
				{ //数据超市
					path: '/dataShop',
					name: 'dataShop',
					component: () => import('../pages/Admin/dataManger/dataShop.vue')
				},
				/* 数据管理  end*/
				/* 运营管理  start*/
				{ //企业追踪
					path: '/userManger',
					name: 'userManger',
					component: () => import('../pages/Admin/operationManger/userManger.vue')
				},
				/* 运营管理  end*/

				{ //新增文章专栏
					path: '/addArticals',
					name: 'addArticals',
					component: () => import('../pages/Admin/addArticals.vue')
				},
				{ //新增政策素材
					path: '/addMaterial',
					name: 'addMaterial',
					component: () => import('../pages/Admin/addMaterial.vue')
				},
				{ //政策排序
					path: '/policySort',
					name: 'policySort',
					component: () => import('../pages/Admin/policySort.vue')
				},

				{ //政策排序
					path: '/policyDeclaration',
					name: 'policyDeclaration',
					component: () => import('../pages/Admin/policyDeclaration.vue')
				},

				{ // 添加发布空间页面
					path: '/AddSpace',
					name: 'AddSpace',
					component: () => import('../pages/Admin/AddSpace.vue')
				},
				{ //入驻企业路由
					path: '/companyIn',
					name: 'companyIn',
					component: () => import('../pages/Admin/companyIn/CompanyIn.vue')
				},
				{ //服务团队列表
					path: '/serviceTeamList',
					name: 'serviceTeamList',
					component: () => import('../pages/Admin/serviceTeam/ServiceTeamList.vue')
				},
				{ //服务团队
					path: '/serviceTeamList/add',
					name: 'SserviceTeamListAdd',
					component: () => import('../pages/Admin/serviceTeam/ServiceTeam.vue')
				},
				{ //编辑服务列表团队
					path: '/serviceTeamList/edit/:id', //PC 超管后台发布机构
					name: 'ServiceTeamListEdit',
					component: () => import('../pages/Admin/serviceTeam/ServiceTeam.vue'),
					props: true
				},
				{
					path: 'jumpInvest', //PC 潜在投资方
					name: 'jumpInvest',
					component: () => import('../pages/Admin/jumpIndex/investerList.vue'),
				},
				{
					path: 'jumpPolicy', //PC 适用政策
					name: 'jumpPolicy',
					component: () => import('../pages/Admin/jumpIndex/policyList.vue'),
				},
				{
					path: 'jumpSpace', //PC 落地园区
					name: 'jumpSpace',
					component: () => import('../pages/Admin/jumpIndex/spacelist.vue'),
				},
				{
					path: '/policyExpress', //政策速递
					name: 'policyExpress',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/policyExpress.vue'),
				},
				{
					path: '/findCompany', //发现企业
					name: 'findCompany',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/findCompany.vue'),
				},
				{
					path: '/discoverCompany', //发现企业
					name: 'discoverCompany',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/discoverCompany.vue'),
				},
				{
					path: '/createBusinessCondition', // 发现企业，创建需求数据
					name: 'createBusinessCondition',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/createBusinessCondition.vue'),
				},
				{
					path: '/discoverStatistics', // 发现企业，创建需求数据
					name: 'discoverStatistics',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/discoverStatistics.vue'),
				},
				{
					path: '/visitorPortrait', //访客画像
					name: 'visitorPortrait',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/VisitorPortrait.vue'),
				},
				// 招商分析
        {
          path: '/enterpriseAnalysis',
					name: 'enterpriseAnalysis',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/enterpriseAnalysis.vue')
        },    

        {
          path: '/enterpriseField',
					name: 'enterpriseField',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/enterpriseField.vue')
				},	
				{
					path: '/screenDisplaywx', //小程序大屏
					name: 'screenDisplaywx',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/xiaoShanInvestmentM.vue'),
				},														
				{
					path: '/userManagement', //用户管理
					name: 'userManagement',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/UserManagement.vue'),
				},
				{
					path: '/sendScience', //发布科技资源
					name: 'sendScience',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/sendScience.vue'),
				},
				{
					path: '/addScience', //添加科技资源
					name: 'addScience',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/addScience.vue'),
				},
				{
					path: '/sendInstrument', //发布科研仪器
					name: 'sendInstrument',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/sendInstrument.vue'),
				},
				{
					path: '/addInstrument', //发布科研仪器
					name: 'addInstrument',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/addInstrument.vue'),
				},
				{
					path: '/orderInfo', //发布科研仪器
					name: 'orderInfo',
					component: () => import('../pages/Admin/EnterpriseAgglomeration/orderInfo.vue'),
				},

			]
		},

	]
})
// router.beforeEach((to, from, next) => { // 路由守卫
//     const token = localStorage.getItem('token')
//     if (to.path === '/login') {
//         this.$router.push()
//     } else  {
//         next()
//     }
// })


export default router
